import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { ElMessage } from 'element-plus';
import { reactive, ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import formRules from './formRules';
export default {
  setup: function setup() {
    var $api = inject('$api');
    var router = useRouter();
    var store = useStore();
    var isLoading = ref(false); // 点击登录按钮，增加loading效果，防止连续快速多次点击

    var ruleForm = reactive({
      // 设置表单字段
      account: '',
      password: ''
    });
    var ruleFormRef = ref(null); // 设置表单DOM

    var rules = reactive(formRules); // 设置表单校验规则
    // const updatePassword = () => { // 跳转忘记密码页面
    //   router.push({
    //     path: '/updatePassword'
    //   })
    // }

    var submitForm = function submitForm(formEl) {
      // 表单校验
      isLoading.value = true;
      formEl.validate(function (valid) {
        if (valid) login();else {
          isLoading.value = false;
          ElMessage({
            message: '请先填写账号和密码后再登录',
            type: 'error'
          });
        }
      });
    };

    var login = function login() {
      // 用户登录
      $api.userLogin(ruleForm).then(function (data) {
        store.commit('user/updateUserInfo', data);
        ElMessage({
          message: '登录成功',
          type: 'success'
        });
        router.replace({
          path: '/'
        });
      }).catch(function () {
        isLoading.value = false;
      });
    };

    var forgetPassword = function forgetPassword() {
      // 忘记密码
      ElMessage('请联系管理员');
    };

    return {
      ruleForm: ruleForm,
      rules: rules,
      submitForm: submitForm,
      ruleFormRef: ruleFormRef,
      isLoading: isLoading,
      forgetPassword: forgetPassword
    };
  }
};