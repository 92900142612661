const formRules = { // 表单校验规则
  account: [
    {
      required: true,
      message: '请输入账号',
      trigger: 'change'
    }
  ],
  password: [
    {
      required: true,
      message: '请输入密码',
      trigger: 'change'
    }
  ]
}
export default formRules
